import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Wallet(props) {
    const [nfts, setNFTs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(undefined);

    const publicPath = 'https://versemail.azurewebsites.net/wallet/public'
    //const publicPath = 'http://localhost:5000/wallet/public'

    useEffect(() => {
        connectWallet();
    }, []);

    useEffect(() => {
        if (props.reload) {
            connectWallet()
        }
    }, [props.reload]);

    const connectWallet = async () => {
        setNFTs([])
        
        try {
            if (!window.solana) {
                setError('Phantom Wallet not detected. Please install Phantom Wallet in your browser.')
                setLoading(false)
                props.setNftCollection(undefined)
                return;
            }

            const { publicKey } = await window.solana.connect();
            const ownerAddress = publicKey.toString();

            getTokenAccounts(ownerAddress);
        } catch (error) {
            setError('Error connecting wallet')
        }
    };

    const getTokenAccounts = async (wallet, solanaConnection) => {
        if (!wallet) return;
        fetchWalletData(wallet)
    }

    const fetchWalletData = (wallet) => {
        axios.get(`${publicPath}/${wallet}`, {  }).then(response => {
            if (response?.data) {
                props.setNftCollection(response?.data?.assets)
                setNFTs(response?.data?.assets?.length ? response?.data?.assets : null)
            }
        }).catch(error => {
            props.setNftCollection(null)
            setNFTs(null)
        });
    }

    if (error)
        return (
            <div className='gallery-msg'>
                <div>
                    {error}
                </div>
            </div>
        );
    else if (!nfts?.length) {
        return (
            <>
                <div className='phantom-sync' onClick={connectWallet}>
                    <i class='bx bxs-ghost' />

                    <div>
                        Sync your Phantom Wallet
                    </div>
                </div>

                <div className='gallery-msg'>
                    <div>{nfts !== null ? 'Loading...' : 'No results'}</div>
                </div>
            </>
        )
    } else {
        return (
            <div className='phantom-sync' onClick={connectWallet}>
                <i class='bx bxs-ghost' />

                <div>
                    Sync your Phantom Wallet
                </div>
            </div>
        )
    }
}