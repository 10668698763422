import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    ConnectionProvider,
    useWallet,
    WalletContextState,
    WalletProvider,
    useConnection,
} from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { ChangeEvent, FC, MouseEventHandler, ReactNode, useMemo, useState } from 'react';
import { ReplaceNft } from 'replace-nft';

import { web3, AnchorError, getProvider } from '@coral-xyz/anchor';
import PhantomModal from './components/PhantomModal';
import './index.scss'
import Navbar from './components/Navbar';

require('./App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const App: FC = () => {
    return (
        <Context>
            <Content />
        </Context>
    );
};
export default App;

let endpoint: string;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    const network = WalletAdapterNetwork.Mainnet;
    endpoint = useMemo(
        () => 'https://orbital-burned-meme.solana-mainnet.quiknode.pro/ca0055634f4f2a21f93e9575e414520376c5f307',
        ['https://orbital-burned-meme.solana-mainnet.quiknode.pro/ca0055634f4f2a21f93e9575e414520376c5f307']
    );

    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new SolflareWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {
    const wallet: WalletContextState = useWallet();

    const { connection } = useConnection();

    // not used anymore
    const newNftsPks = new web3.PublicKey('H6DzWB724ALJiXq2fzGgegMNmGk1iwJXRy1ERK551qo3');
    const [reload, setReload] = useState(false);

    const handleReplaceNft = async (nft: any) => {
        setReload(false)

        try {
            if (!wallet.connected) {
                await wallet.connect();
            }

            const sdk = new ReplaceNft({
                wallet,
                connection,
            });

            let tx = await sdk.replaceNft(new web3.PublicKey(nft), 2);
        } catch (e) {
            console.log('ERROR: ', e);
        }
       
    };

    return (
        <>
            <Navbar />
            <PhantomModal handleReplaceNft={handleReplaceNft} WalletMultiButton={<WalletMultiButton />} reload={reload}/>
        </>
    )
};
